import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import AddToCalendarHOC from "react-add-to-calendar-hoc";
import moment from "moment-timezone";

import Layout from "../components/Layout";
import FullWidthImage from "../components/FullWidthImage";
import ScrollDivider from "../components/ScrollDivider";

const useOutsideClick = (callback) => {
  const ref = React.useRef();

  React.useEffect(() => {
    const handleClick = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback(event);
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [ref, callback]);

  return ref;
};

function Button({ children, onClick }) {
  return (
    <div className="dropdown-trigger" style={{ width: "100%" }}>
      <button className="button center is-fullwidth" onClick={onClick}>
        {children}
      </button>
    </div>
  );
}

function Dropdown({ children, onRequestClose }) {
  const ref = useOutsideClick(onRequestClose);

  return (
    <>
      <div className="dropdown-menu" id="dropdown-menu" role="menu">
        <div className="dropdown-content" ref={ref}>
          {children}
        </div>
      </div>
    </>
  );
}

// eslint-disable-next-line
export const ProgrammaPageTemplate = ({
  image,
  title,
  heading,
  color,
  description,
  upcommingEvents,
  events,
}) => {
  const heroImage = getImage(image) || image;
  const AddToCalendarDropdown = AddToCalendarHOC(Button, Dropdown);

  return (
    <div>
      <FullWidthImage
        img={heroImage}
        title={title}
        height={"90vh"}
        color={color}
        imgPosition={"center 70%"}
      />
      <ScrollDivider color={color} button={true} anchor="programma#main" />
      <section
        className="section section--gradient"
        style={{ background: color }}
      >
        <div className="container" id="main">
          <div className="section">
            <div className="columns">
              <div className="column is-10 is-offset-1">
                <div className="content">
                  <div className="columns">
                    <div className="column is-12">
                      <h3 className="has-text-weight-semibold is-size-3 has-text-centered is-uppercase">
                        {heading}
                      </h3>
                      <p className="theme-italic has-text-centered">
                        {description}
                      </p>
                      <div className="columns is-multiline">
                        {upcommingEvents &&
                          upcommingEvents.map((event, i) => (
                            <div
                              className="columns"
                              style={{
                                alignItems: "center",
                                columnGap: "1rem",
                                flexDirection:
                                  i % 2 === 0 ? "row-reverse" : "row",
                              }}
                              key={i}
                            >
                              <div className="column is-4">
                                <div className="featured-image">
                                  <img
                                    src={event.image}
                                    alt={event.title}
                                    className="bordered"
                                  />
                                </div>
                                <div className="columns">
                                  <div className="column is-6">
                                    <AddToCalendarDropdown
                                      className="dropdown is-active is-fullwidth"
                                      buttonText="Zet in Agenda"
                                      linkProps={{
                                        className: "dropdown-item",
                                      }}
                                      event={{
                                        description: event.description,
                                        duration: moment
                                          .duration(
                                            moment(event.endDatetime).diff(
                                              moment(event.startDatetime)
                                            )
                                          )
                                          .asHours(),
                                        endDatetime: moment(
                                          event.endDatetime
                                        ).format("YYYYMMDDTHHmmssZ"),
                                        location: event.location,
                                        startDatetime: moment(
                                          event.startDatetime
                                        ).format("YYYYMMDDTHHmmssZ"),
                                        title: event.title,
                                      }}
                                    />
                                  </div>
                                  <div className="column is-6">
                                    {event.ticketUrl ? (
                                      <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href={event.ticketUrl}
                                        className="button is-primary is-fullwidth"
                                      >
                                        Tickets & Info
                                      </a>
                                    ) : (
                                      <button
                                        className="button is-primary is-fullwidth"
                                        disabled
                                      >
                                        Tickets & Info
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="column is-8">
                                <h2 className="title is-size-3 is-uppercase">
                                  {event.title}
                                </h2>
                                <div className="theme-body has-text-white">
                                  <p className="theme-italic">
                                    {moment(event.startDatetime)
                                      .tz("Europe/Amsterdam")
                                      .format("DD.MM.YYYY HH:mm") +
                                      "-" +
                                      moment(event.endDatetime)
                                        .tz("Europe/Amsterdam")
                                        .format("HH:mm")}
                                  </p>
                                  {event.description}
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                      <h3 className="has-text-weight-semibold is-size-3 has-text-centered is-uppercase">
                        Volledige Programma
                      </h3>
                      <div className="timeline is-centered">
                        <header className="timeline-header">
                          <span className="tag is-medium is-primary">
                            Start lustrumjaar
                          </span>
                        </header>
                        {events &&
                          events.map((event, i) => (
                            <div className="timeline-item" key={i}>
                              {event.image ? (
                                <div className="timeline-marker is-image is-32x32">
                                  <img src={event.image} alt="Dorsten" />
                                </div>
                              ) : (
                                <div className="timeline-marker"></div>
                              )}
                              <div className="timeline-content">
                                <p className="heading">{event.dateString}</p>
                                <p>{event.title}</p>
                              </div>
                            </div>
                          ))}
                        <div className="timeline-header">
                          <span className="tag is-medium is-primary">
                            Einde Lustrumjaar
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

ProgrammaPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  events: PropTypes.object,
};

const ProgrammaPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout navColor={frontmatter.color}>
      <ProgrammaPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        description={frontmatter.description}
        color={frontmatter.color}
        upcommingEvents={frontmatter.events
          .filter((x) => new Date(x.startDatetime) >= new Date())
          .sort(function (a, b) {
            return new Date(a.startDatetime) - new Date(b.startDatetime);
          })}
        events={frontmatter.events}
      />
    </Layout>
  );
};

ProgrammaPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ProgrammaPage;

export const programmaPageQuery = graphql`
  query ProgrammaPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        color
        heading
        description
        events {
          title
          dateString
          description
          location
          image
          startDatetime
          endDatetime
          ticketUrl
        }
      }
    }
  }
`;
